import axios from 'axios';
import * as R from 'ramda';
// helpers/constants
import * as H from '../helpers/helpers';
import { getAuthTokenFromSession } from '../helpers/storage';
import { isDevelopment} from '../helpers/env.js';
//////////////////////////////////////////////////
const qs = require('qs');
//////////////////////////////////////////////////

const envConfig = require('../../config/app.config.js');

const makeRequestHeaders = (customHeaders: Object, plainText: boolean = false) => {
  let token;
  let sessionHeaders = {};

  try {
    token = getAuthTokenFromSession();
  } catch (error) {
    token = null;
  }

  if (token) {
    sessionHeaders = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (H.isTrue(plainText)) return { ...sessionHeaders, ...customHeaders, 'Content-Type': 'text/plain' };

  return { ...sessionHeaders, ...customHeaders };
};

// TODO: add port to los service
const servicesPorts = {
  // los: null,
  menu: '6006',
  user: '6001',
  fleet: '6008',
  route: '6005',
  report: '6015',
  carrier: '6003',
  customer: '6020',
  distance: '6007',
  templates: '6004',
  reference: '6014',
  loadboard: '6019',
  statistic: '6017',
  enterprise: '6002',
  integration: '6011',
  notification: '6018',
  configuration: '6010',
  'mail-sending': '6009',
  'document-template': '6016',
};

const instance = axios.create();

const getAxiosInstanceWithConfigs = (configs: Object) => axios.create(configs);

const returnResponse = (response: Object) => {
  if (isDevelopment) {
    console.debug('========== RESPONSE ==========\n');
    console.log(response);
    console.debug('=================================');
  }

  return response;
};

const returnError = (error: string) => {
  if (isDevelopment) {
    console.error('========== ERROR ==========\n');
    console.error(error);
    console.error('=================================');
  }

  return error.response;
};

instance.interceptors.response.use(
  returnResponse,
  returnError,
);

const wsProtocol = 'ws://';
const wssProtocol = 'wss://';
const localhost = 'localhost';
const httpProtocol = 'http://';
const httpsProtocol = 'https://';

const getUrl = (service: string, domain: string, path: string) => {
  const protocol = H.ifElse(R.equals(domain, localhost), httpProtocol, httpsProtocol);

  return H.ifElse(
    R.equals(domain, localhost),
    `${protocol}${localhost}:${servicesPorts[service]}/${path}`,
    `${protocol}${service}.${domain}/${path}`,
  );
};

export const getWSUrl = (service: string, domain: string, path: string) => {
  const protocol = H.ifElse(R.equals(domain, localhost), wsProtocol, wssProtocol);

  return H.ifElse(
    R.equals(domain, localhost),
    `${protocol}${localhost}:${servicesPorts[service]}/${path}`,
    `${protocol}${service}.${domain}/${path}`,
  );
};

export const createApiUrl = (servicePath: string) => {
  const { apiDomain } = envConfig;
  const splited = R.split(':', servicePath);
  const service = splited[0];
  const path = splited[1];

  return getUrl(service, apiDomain, path);
};

export const createWSApiUrl = (servicePath: string) => {
  const { apiDomain } = envConfig;
  const splited = R.split(':', servicePath);
  const service = splited[0];
  const path = splited[1];

  return getWSUrl(service, apiDomain, path);
};

export function sendRequest(method: string, servicePath: string, options: Object = {}, isUrlEncoded: boolean) { // eslint-disable-line
  const configs = {
    method,
    data: R.or(options.data, {}),
    url: createApiUrl(servicePath),
    params: R.or(options.params, {}),
    headers: makeRequestHeaders(R.or(options.headers, {}), options.plainText),
  };

  if (H.isNotNil(options.auth)) configs.auth = options.auth;

  if (R.and(isUrlEncoded, R.equals(method, 'post'))) configs.data = qs.stringify(options.data);

  if (options.resType) configs.responseType = options.resType;

  return instance(configs);
}

export function sendRequestWithQSParamsSerializer(method: string, servicePath: string, options: Object = {}) {
  const configs = {
    method,
    data: R.or(options.data, {}),
    url: createApiUrl(servicePath),
    params: R.or(options.params, {}),
    headers: makeRequestHeaders(R.or(options.headers, {}), options.plainText),
  };

  if (H.isNotNil(options.auth)) configs.auth = options.auth;

  if (options.resType) configs.responseType = options.resType;

  const axiosConfigs = {
    paramsSerializer: (params: Object) => qs.stringify(params),
  };

  const axiosInstance = getAxiosInstanceWithConfigs(axiosConfigs);

  axiosInstance.interceptors.response.use(
    returnResponse,
    returnError,
  );

  return axiosInstance(configs);
}

// NOTE: use it for request testing without axios
export async function fetchRequest(method: string, servicePath: string, options: Object = {}) {
  const { url, data, contentType, responseType } = {
    data: R.or(options.data, {}),
    url: createApiUrl(servicePath),
    params: R.or(options.params, {}),
    responseType: R.path(['resType'], options),
    contentType: R.pathOr('application/json', ['contentType'], options),
  };

  const headers = makeRequestHeaders(R.or(options.headers, {}));

  if (H.isNotNilAndNotEmpty(responseType)) headers['response-type'] = responseType;

  headers['content-type'] = contentType;

  const response = await fetch(url, { // eslint-disable-line
    headers,
    method: R.toUpper(method),
    body: JSON.stringify(data),
  });

  console.log('/////////////////////////////////////////////////////fetchRequest', response);

  const json = await response.json();

  console.log('/////////////////////////////////////////////////////fetchRequest', json);

  return response;
}

export async function fetchRequestByExternalUrl(method: string, url: string, options: Object = {}) {
  const { data, contentType, responseType, headers } = {
    data: R.or(options.data, {}),
    params: R.or(options.params, {}),
    headers: R.or(options.headers, {}),
    responseType: R.path(['resType'], options),
    contentType: R.pathOr('application/json', ['contentType'], options),
  };

  if (H.isNotNilAndNotEmpty(responseType)) headers['response-type'] = responseType;

  headers['content-type'] = contentType;

  const optionsToUse = {
    headers,
    method: R.toUpper(method),
  };

  if (H.isNotNilAndNotEmpty(data)) optionsToUse.body = JSON.stringify(data);

  const response = await fetch(url, optionsToUse); // eslint-disable-line

  const json = await response.json();

  const responseToUse = {
    data: json,
    status: response.status,
  };

  return responseToUse;
}
