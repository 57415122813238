import * as R from 'ramda';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
// features
import { updateConfigsSuccess } from '../features/configurations/actions';
import { makeSelectCurrentBranchGuid } from '../features/branch/selectors';
import { getInitialBranchConfigsSuccess, receivedSwitchBranchSuccess } from '../features/branch/actions';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
//////////////////////////////////////////////////

const configNames = [
  GC.UI_HIDDEN_TABS,
  GC.UI_USE_MUI_CALENDAR,
  GC.UI_ORDER_ORDER_ENTRY,
  GC.UI_HIDDEN_MENU_ITEMS,
  GC.UI_USE_MENU_VERSION_2,
  GC.UI_OPEN_LOAD_IN_SIDEBAR,
  GC.UI_USE_NEW_FLEET_PROFILE,
  GC.UI_CALENDAR_TIME_INTERVAL,
  GC.UI_USE_NEW_DISPATCH_BOARD,
  GC.UI_USE_NEW_CARRIER_PROFILE,
  GC.UI_TRACKING_USE_GOOGLE_MAP,
  GC.UI_LOADBOARD_AUTODIALAPPNAME,
  GC.UI_REPLACE_DOLLAR_SYMBOL_WITH_US,
  GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP,
  GC.UI_ROUTE_LIST_CLO_DEFAULT_ORGANIZE_BY,
  GC.UI_ROUTE_LIST_TEL_DEFAULT_ORGANIZE_BY,
  GC.UI_TEL_CARRIER_RATE_SHOW_LANE_HISTORY,
  //
  GC.GENERAL_BRANCH_TIMEZONE,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_BRANCH_DATE_TIME_FORMAT,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  GC.GENERAL_BRANCH_USE_COMMISSION_ASSIGNMENT,
  //
  GC.CLO_GENERAL_USE_CONTAINERS,
  GC.CLO_GENERAL_APPROVAL_REQUIRED,
  GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR,
  GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME,
  //
  GC.TEL_DRIVER_RATE_SHOW_RAILWAY_SECTION,
  GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT,
  //
  GC.RATE_ENGINE_MULTIPLE_CURRENCY_CHARGES,
  GC.RATE_ENGINE_SEPARATE_LINE_HAUL_FOR_EVERY_CLO,
  //
  GC.CARRIER_REQUIRED_FIELDS,
  GC.CARRIER_GENERAL_USE_FLEET_TRAILER,
  GC.CARRIER_GENERAL_SAFER_WATCH_SYNC_REQUIRED,
  //
  GC.FLEET_GENERAL_SHOW_DASHBOARDS,
  //
  GC.TRAILER_MANAGE_TRAILERS_FROM,
  //
  GC.INTEGRATION_SAFER_WATCH_CUSTOMER_KEY,
];

function* handleSetBranchConfigsToStorageRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const names = R.join(',', configNames);

    const branchConfigOptions = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, branchConfigOptions);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      let amousConfig = G.getAmousConfigFromWindow();

      const mappedConfigs = R.pathOr({}, ['configs'], G.mapConfigValuesByName(data));
      const mergedConfigs = R.mergeRight(R.pathOr({}, ['configs'], amousConfig), mappedConfigs);

      amousConfig = R.assocPath(['configs'], mergedConfigs, amousConfig);

      yield fork(G.setAmousConfigToWindow, amousConfig);

      yield put(getInitialBranchConfigsSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleSetBranchConfigsToStorageRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'handleSetBranchConfigsToStorageRequest exception'));
  }
}

function* handleSetShowAdvancePaymentToStorageRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.advancePaymentConfigList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const showAdvancePayment = G.isNotNilAndNotEmpty(data);

      yield fork(G.setAmousConfigToWindow, { configs: { showAdvancePayment } });
    } else {
      yield call(G.handleFailResponse, res, 'handleSetShowAdvancePaymentToStorageRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'handleSetShowAdvancePaymentToStorageRequest exception'));
  }
}

function* handleSetShowTransportingCarrierToStorageRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierRateIntegrationConfigList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const showTransportingCarrier = R.any(R.prop(GC.FIELD_ENABLED), R.or(data, []));

      yield fork(G.setAmousConfigToWindow, { configs: { showTransportingCarrier } });
    } else {
      yield call(G.handleFailResponse, res, 'handleSetShowTransportingCarrierToStorageRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'handleSetShowTransportingCarrierToStorageRequest exception'));
  }
}

function* handleSetShowCarrierSynchronizationToStorageRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierSynchronizationConfig, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const showCarrierSynchronization = G.isNotNilAndNotEmpty(data);

      const carrierSynchronizationType = R.prop(GC.FIELD_INTEGRATION_TYPE, data);

      yield fork(G.setAmousConfigToWindow, { configs: { showCarrierSynchronization, carrierSynchronizationType } });
    } else {
      yield call(G.handleFailResponse, res, 'handleSetShowCarrierSynchronizationToStorageRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'handleSetShowCarrierSynchronizationToStorageRequest exception'));
  }
}

function* handleSetContainerTypeListToStorageRequest() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.containerTypeList);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield fork(G.setItemToWindow, 'amousContainerTypeList', data);
    } else {
      yield call(G.handleFailResponse, res, 'handleSetContainerTypeListToStorageRequest fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleSetContainerTypeListToStorageRequest exception');
  }
}

function* handleSetConfigToStorageRequest() {
  try {
    yield all([
      call(handleSetBranchConfigsToStorageRequest),
      call(handleSetContainerTypeListToStorageRequest),
      call(handleSetShowAdvancePaymentToStorageRequest),
      call(handleSetShowTransportingCarrierToStorageRequest),
      call(handleSetShowCarrierSynchronizationToStorageRequest),
    ]);
  } catch (error) {
    yield call(G.handleException, error, 'handleSetConfigToStorageRequest exception');
  }
}

function* storageWatcherSaga() {
  yield takeLatest(receivedSwitchBranchSuccess, handleSetConfigToStorageRequest);
  yield takeLatest(updateConfigsSuccess, handleSetBranchConfigsToStorageRequest);
}

export default storageWatcherSaga;
