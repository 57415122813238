import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { getRequiredLocaleTxt } from '../helpers';
//////////////////////////////////////////////////

export const validationSchemaCommentObject = {
  [GC.FIELD_COMMENTS]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
};

export const validationSchemaFaxObject = {
  [GC.FIELD_FAX]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(1, G.getShouldBeFromToLocaleTxt(0, 20))
    .max(20, G.getShouldBeFromToLocaleTxt(0, 20)),
};

export const validationSchemaSpecialInstructionsObject = {
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 5000))
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 5000)),
};

export const validationSchemaInternalInstructionsObject = {
  [GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS]: Yup.string()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBeFromToLocaleTxt(0, 2000))
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
};

export const validationSchemaLocationTypeObject = {
  [GC.FIELD_LOCATION_TYPE]: Yup.string()
    .nullable(true)
    .notRequired(),
};

export const validationSchemaPayTermObject = {
  [GC.FIELD_PAYMENT_TERM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaLatLngObject = {
  [GC.FIELD_LATITUDE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LONGITUDE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaContactObject = {
  [GC.FIELD_CONTACT_NAME]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(50, G.getShouldBeFromToLocaleTxt(0, 50)),
  [GC.FIELD_EMAIL]: Yup.string()
    .nullable(true)
    .notRequired()
    .email(G.getFieldIsNotValidEmailTxt()),
  [GC.FIELD_PHONE_EXTENSION]: Yup.string()
    .nullable(true)
    .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
  [GC.FIELD_PHONE]: Yup.string()
    .nullable(true)
    .notRequired()
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_COMMENTS]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
};

const validationSchemaLocationObjectDefault = {
  [GC.FIELD_LOCATION_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_TEMPLATE_ID]: Yup.string()
    .nullable(true)
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_ADDRESS_1]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
  [GC.FIELD_ADDRESS_2]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_ZIP]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
};

export const validationSchemaLocationObject = {
  ...validationSchemaLocationObjectDefault,
  [GC.FIELD_ADDRESS_1]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
};

export const validationSchemaLocationObject2 = {
  ...validationSchemaLocationObjectDefault,
  [GC.FIELD_ADDRESS_1]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
};

export const validationSchemaQuoteLocationObject = {
  [GC.FIELD_LOCATION_NAME]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_TEMPLATE_ID]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_ADDRESS_1]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
  [GC.FIELD_ADDRESS_2]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(200, G.getShouldBeFromToLocaleTxt(0, 200)),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_ZIP]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
};

export const validationEventDateTimeObject = {
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const getValidationSchemaAppDateTimeObject = (values: Object) => {
  if (G.isNilOrEmpty(R.prop(GC.FIELD_LOAD_APPOINTMENT_DATE, values))) return null;

  return {
    [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  };
};

export const validationEventDateTimeObject2 = {
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .notRequired(),
};

export const validationEventDateTimeObject3 = {
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
};


export const validationEventDateTimeObject4 = {
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_EARLY_TIME]: Yup.string()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
};

export const validationEventDateTimeObject5 = {
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: Yup.date()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_EARLY_TIME]: Yup.string()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_LOAD_EVENT_LATE_TIME]: Yup.string()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaFleetObject = {
  [GC.FIELD_PRIMARY_DRIVER_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRUCK_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRAILER_GUIDS]: Yup.array()
    .nullable(true)
    .notRequired()
    .max(3, G.getShouldBePickOnlyLocaleTxt(3)),
};

export const validationSchemaFleetWithoutTrailersObject = {
  [GC.FIELD_PRIMARY_DRIVER_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TRUCK_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaTripServiceObject = {
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_SERVICE_TYPE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_SERVICE_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBePositiveLocaleTxt())
    .integer(G.getShouldBeIntegerLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
};

export const validationSchemaPriceSheetItems = {
  [GC.SYSTEM_LIST_ITEMS]: Yup.array().of(Yup.object().shape({
    [GC.FIELD_ITEM_WEIGHT_TYPE]: Yup.string().when(
      GC.FIELD_ITEM_WEIGHT,
      {
        is: G.isNotNilAndNotEmpty,
        otherwise: (schema: Object) => schema.notRequired(),
        then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      },
    ),
    [GC.FIELD_PACKAGE_TYPE]: Yup.string().when(
      GC.FIELD_ITEM_QUANTITY,
      {
        is: G.isNotNilAndNotEmpty,
        otherwise: (schema: Object) => schema.notRequired(),
        then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      },
    ),
    [GC.FIELD_ITEM_VOLUME_UOM]: Yup.string().when(
      GC.FIELD_ITEM_VOLUME,
      {
        is: G.isNotNilAndNotEmpty,
        otherwise: (schema: Object) => schema.notRequired(),
        then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      },
    ),
    [GC.FIELD_ITEM_WEIGHT]: Yup.number()
      .notRequired()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .transform((value: number) => G.ifElse(G.isNotNaN(value), value)),
    [GC.FIELD_ITEM_QUANTITY]: Yup.number()
      .notRequired()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .integer(G.getShouldBeIntegerLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .transform((value: number) => G.ifElse(G.isNotNaN(value), value)),
    [GC.FIELD_ITEM_VOLUME]: Yup.number()
      .notRequired()
      .nullable(true)
      .min(0, G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .transform((value: number) => G.ifElse(G.isNotNaN(value), value)),
  })),
};

export const validationSchemaTripServiceObject2 = {
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_SERVICE_TYPE]: Yup.string()
    .nullable(true)
    .notRequired(),
};

export const validationSchemaTripTotalObject = {
  [GC.FIELD_MODE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TOTAL_TRIP_VOLUME]: Yup.number()
    .nullable(true)
    .min(0, G.getShouldBePositiveLocaleTxt())
    .typeError(G.getShouldBeIntegerLocaleTxt()),
};

export const getValidationSchemaTripTotalObject = ({ totalTripVolume }: Object) => {
  if (G.isNotNilAndNotEmpty(totalTripVolume)) {
    return R.assoc(
      GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
      Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      validationSchemaTripTotalObject,
    );
  }

  return validationSchemaTripTotalObject;
};

export const validationSchemaDispatcherInfoObject = {
  [GC.FIELD_COMMENTS]: Yup.string().nullable(true),
  [GC.FIELD_DISPATCH_NAME]: Yup.string().nullable(true),
  [GC.FIELD_DISPATCH_EMAILS]: Yup.array().nullable(true),
  [GC.FIELD_DISPATCH_PHONE]: Yup.string()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
};

export const validationSchemaMarginViolationObject = {
  [GC.FIELD_MARGIN_VIOLATION_REASON]: Yup.string()
    .notRequired()
    .nullable(true),
  [GC.FIELD_MARGIN_VIOLATION_NOTE]: Yup.string()
    .notRequired()
    .nullable(true)
    .max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
};

export const validationSchemaCarrierObject = {
  [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: Yup.object({
    [GC.FIELD_CARRIER_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_CARRIER_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
    [GC.FIELD_CARRIER_SCAC]: Yup.string()
      .nullable(true),
    [GC.FIELD_CARRIER_USDOT_NUMBER]: Yup.string()
      .nullable(true),
    [GC.FIELD_PRIMARY_DRIVER_PHONE]: Yup.string()
      .nullable(true)
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_SECONDARY_DRIVER_PHONE]: Yup.string()
      .nullable(true)
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  }),
};

export const validationSchemaServiceVendorObject = {
  [GC.FIELD_SERVICE_VENDOR_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

export const validationSchemaInvoiceObject = {
  [GC.FIELD_INVOICE_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_INVOICE_STATUS]: Yup.string()
    .notRequired()
    .nullable(true),
  [GC.FIELD_INVOICE_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_INVOICE_NET_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .min(0, G.getShouldBePositiveLocaleTxt())
    .integer(G.getShouldBeIntegerLocaleTxt()),
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_INVOICE_CHECK_NUMBER]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(30, G.getShouldBeFromToLocaleTxt(0, 30)),
  [GC.FIELD_INVOICE_CHECK_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CHECK_AMOUNT]: Yup.number()
    .nullable(true)
    .notRequired()
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(12000000, G.getShouldBeFromToLocaleTxt(0, 12000000)),
};

export const validationSchemaTransactionStopObject = {
  ...validationSchemaLatLngObject,
  [GC.FIELD_LOCATION_NAME]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(40, G.getShouldBeFromToLocaleTxt(0, 40)),
  [GC.FIELD_CITY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_STATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
  [GC.FIELD_COUNTRY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(85, G.getShouldBeFromToLocaleTxt(0, 85)),
};

const validationSchemaFuelCardLineObject = Yup.object().shape({
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_PRODUCT_NAME]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_PRICE]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_QUANTITY]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_TOTAL]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
});

export const validationSchemaFuelCardObject = {
  [GC.FIELD_SOURCE]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER]: Yup.string()
    .nullable(true)
    .required(getRequiredLocaleTxt())
    .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
  [GC.FIELD_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_TRANSACTION_DATE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_FUEL_CARDS_LINES]: Yup.array()
    .of(validationSchemaFuelCardLineObject)
    .nullable(true)
    .notRequired(),
};
