import React from 'react';
import * as R from 'ramda';
// features
import BusinessHours, { withBusinessHours } from '../features/business-hours';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { PAYMENT_TERM_OPTIONS } from '../helpers/options';
// icons
import * as I from '../svgs';
// ui
import { Box, Flex } from '../ui';
//////////////////////////////////////////////////

const BusinessHoursSection = withBusinessHours((props: Object) => (
  <Box width={470}>
    <Flex>
      <Box>{G.getWindowLocale('titles:business-hours', 'Business Hours')}</Box>
      {
        G.isNilOrEmpty(props.operationHour) &&
        <Box ml={10} onClick={props.handleOpenBusinessHours}>{I.plusRound()}</Box>
      }
    </Flex>
    {
      G.isNotNilAndNotEmpty(props.operationHour) &&
      <Box my='5px' p='5px 10px' border='1px solid' borderColor={G.getTheme('colors.black')}>
        <BusinessHours iconsML='0px' maxWidth={260} {...props} />
      </Box>
    }
  </Box>
));

export const defaultPayTermFields = {
  [GC.FIELD_PAYMENT_TERM]: null,
};

export const defaultLatLngFields = {
  [GC.FIELD_LATITUDE]: null,
  [GC.FIELD_LONGITUDE]: null,
};

export const defaultEventDateTimeFields = {
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: null,
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: null,
};

export const defaultContactFields = {
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CONTACT_NAME]: null,
};

export const defaultLocationFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_ADDRESS_2]: null,
  [GC.FIELD_TEMPLATE_ID]: null,
  [GC.FIELD_LOCATION_NAME]: null,
};

const commonProps = {
  width: 230,
  labelPl: '0px',
  labelWidth: '100%',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
};

const locationFields = [
  {
    ...commonProps,
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...commonProps,
    type: 'text',
    loc: 'titles:template-id',
    fieldName: GC.FIELD_TEMPLATE_ID,
  },
  {
    ...commonProps,
    zIndex: 12,
    isRequired: true,
    loc: 'titles:address1',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
  },
  {
    ...commonProps,
    type: 'text',
    loc: 'titles:address2',
    fieldName: GC.FIELD_ADDRESS_2,
  },
  {
    ...commonProps,
    type: 'text',
    isRequired: true,
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...commonProps,
    type: 'text',
    isRequired: true,
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...commonProps,
    type: 'text',
    isRequired: false,
    fieldName: GC.FIELD_ZIP,
    loc: 'titles:postal-code',
  },
  {
    ...commonProps,
    isWrapped: true,
    isRequired: true,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
  },
];

const latLongFields = [
  {
    ...commonProps,
    type: 'text',
    disabled: true,
    isRequired: true,
    loc: 'titles:latitude',
    fieldName: GC.FIELD_LATITUDE,
  },
  {
    ...commonProps,
    type: 'text',
    disabled: true,
    isRequired: true,
    loc: 'titles:longitude',
    fieldName: GC.FIELD_LONGITUDE,
  },
];

const contactFields = [
  {
    ...commonProps,
    type: 'text',
    isRequired: false,
    loc: 'titles:contact-name',
    fieldName: GC.FIELD_CONTACT_NAME,
  },
  {
    ...commonProps,
    isRequired: false,
    type: 'phoneNumber',
    loc: 'titles:phone',
    fieldName: GC.FIELD_PHONE,
  },
  {
    ...commonProps,
    type: 'text',
    isRequired: false,
    loc: 'titles:email',
    fieldName: GC.FIELD_EMAIL,
  },
  {
    ...commonProps,
    type: 'text',
    isRequired: false,
    loc: 'titles:comments',
    fieldName: GC.FIELD_COMMENTS,
  },
];

const eventDateTimeFields = [
  {
    ...commonProps,
    zIndex: 12,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: true,
    loc: 'titles:early-date',
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
  },
  {
    ...commonProps,
    zIndex: 11,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: true,
    loc: 'titles:late-date',
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
  },
];

const appointmentFields = [
  {
    ...commonProps,
    type: 'text',
    loc: 'titles:appointment-number',
    fieldName: GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  },
  {
    ...commonProps,
    zIndex: 13,
    type: 'calendar',
    isClearable: true,
    loc: 'titles:appointment-date',
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_LOAD_APPOINTMENT_DATE,
  },
  {
    ...commonProps,
    type: 'multi',
    jc: 'space-between',
    loc: 'titles:appointment-time-from-to',
    fields: [
      {
        width: 110,
        type: 'time',
        isClearable: true,
        timeSelection: true,
        fieldName: GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
      },
      {
        width: 110,
        type: 'time',
        isClearable: true,
        timeSelection: true,
        fieldName: GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
      },
    ],
  },
];

const locationFieldset = {
  fields: [
    ...locationFields,
  ],
};

const locationWithLatLongFieldset = {
  fields: [
    ...locationFields,
    ...latLongFields,
  ],
};

const contactFieldset = {
  width: 255,
  flexDirection: 'column',
  fields: [
    ...contactFields,
  ],
};

const dateTimesWithContactFieldset = {
  fields: [
    ...R.take(3, contactFields),
    ...appointmentFields,
    ...eventDateTimeFields,
    {
      ...commonProps,
      width: 470,
      type: 'custom',
      fieldName: 'custom',
      labelDisplay: 'none',
      component: (props: Object) =>
        <BusinessHoursSection
          {...props}
          componentType='event'
          useFormikSetFieldValue={true}
          operationHour={R.path(['values', GC.FIELD_OPERATION_HOUR], props)}
        />,
    },
    {
      ...commonProps,
      width: 470,
      type: 'textarea',
      isRequired: false,
      loc: 'titles:comments',
      fieldName: GC.FIELD_COMMENTS,
    },
  ],
};

const dateTimesWithoutAppointmentFieldset = {
  fields: [
    ...eventDateTimeFields,
    ...R.take(3, contactFields),
    {
      ...commonProps,
      width: 470,
      type: 'custom',
      fieldName: 'custom',
      labelDisplay: 'none',
      component: (props: Object) =>
        <BusinessHoursSection
          {...props}
          componentType='event'
          useFormikSetFieldValue={true}
          operationHour={R.path(['values', GC.FIELD_OPERATION_HOUR], props)}
        />,
    },
    {
      ...commonProps,
      width: 470,
      type: 'textarea',
      isRequired: false,
      loc: 'titles:comments',
      fieldName: GC.FIELD_COMMENTS,
    },
  ],
};

const billToInputWrapperStyles = { mb: 25, width: 200 };

export const billToSectionSettings = [
  {
    type: 'text',
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template ID'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_PAYMENT_TERM,
    inputWrapperStyles: billToInputWrapperStyles,
    label: ['titles:payment-terms', 'Payment Terms'],
    options: [GC.EMPTY_OPTION_OBJECT, ...PAYMENT_TERM_OPTIONS],
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
    inputWrapperStyles: { ...billToInputWrapperStyles, zIndex: 11 },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    isRequired: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: ['titles:city', 'City'],
    inputWrapperStyles: { ...billToInputWrapperStyles, zIndex: 11 },
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    isRequired: true,
    fieldName: GC.FIELD_ZIP,
    type: 'addressAutocomplete',
    label: ['titles:postal-code', 'Postal Code'],
    inputWrapperStyles: { ...billToInputWrapperStyles, zIndex: 11 },
  },
  {
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
];

export const getBillToContactSettings = (commentsWidth: number) => [
  {
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: billToInputWrapperStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    inputWrapperStyles: billToInputWrapperStyles,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'multiEmail',
    fieldName: GC.FIELD_EMAILS,
    shouldOverrideEmails: true,
    label: ['titles:emails', 'Emails'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax', 'FAX'],
    inputWrapperStyles: billToInputWrapperStyles,
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { width: R.or(commentsWidth, '100%') },
  },
];

const remitToInputWrapperStyles = { mb: 25, width: 200 };

export const remitToSectionSettings = [
  {
    type: 'text',
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template ID'],
    inputWrapperStyles: {...remitToInputWrapperStyles, width: '49%'},
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
    inputWrapperStyles: {...remitToInputWrapperStyles, width: '49%'},
  },
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    isRequired: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: ['titles:city', 'City'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    isRequired: false,
    fieldName: GC.FIELD_ZIP,
    type: 'addressAutocomplete',
    label: ['titles:postal-code', 'Postal Code'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
];

export const remitToContactSettings = [
  {
    type: 'text',
    isRequired: false,
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: remitToInputWrapperStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    isRequired: false,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    type: 'number',
    isRequired: false,
    fieldName: GC.FIELD_PHONE_EXTENSION,
    inputWrapperStyles: remitToInputWrapperStyles,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    isRequired: false,
    type: 'multiEmail',
    fieldName: GC.FIELD_EMAILS,
    shouldOverrideEmails: true,
    label: ['titles:emails', 'Emails'],
    inputWrapperStyles: remitToInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax', 'Fax'],
    inputWrapperStyles: {
      ...remitToInputWrapperStyles,
      margin: '0px auto 25px 15px',
    },
  },
  {
    type: 'textarea',
    isRequired: false,
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: {width: '100%'},
    label: ['titles:comments', 'Comments'],
  },
];

export const locationSectionSettings = [
  locationFieldset,
  contactFieldset,
];

export const stopLocationSectionSettings = [
  locationWithLatLongFieldset,
  contactFieldset,
];

export const stopSectionSettings = [
  locationWithLatLongFieldset,
  dateTimesWithContactFieldset,
];

export const stopSectionWithoutAppointmentSettings = [
  locationWithLatLongFieldset,
  dateTimesWithoutAppointmentFieldset,
];

const inputWrapperStyles = {
  mb: 25,
  width: 305,
};

const locationFieldset1 = {
  flexDirection: 'column',
  justifyContent: 'flex-end',
  fields: [
    {
      type: 'text',
      inputWrapperStyles,
      fieldName: GC.FIELD_LOCATION_NAME,
      label: ['titles:location-name', 'Location Name'],
    },
    {
      type: 'addressAutocomplete',
      fieldName: GC.FIELD_ADDRESS,
      label: ['titles:address', 'Adress'],
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
      placeholder: G.getWindowLocale('titles:search-location', 'Search Location'),
    },
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_CITY,
      label: ['titles:city', 'City'],
    },
    {
      type: 'text',
      inputWrapperStyles,
      fieldName: GC.FIELD_ZIP,
      label: ['titles:postal-code', 'Postal Code'],
    },
    {
      type: 'text',
      disabled: true,
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_LATITUDE,
      label: ['titles:latitude', 'Latitude'],
    },
  ],
};

const locationFieldset2 = {
  flexDirection: 'column-reverse',
  fields: [
    {
      type: 'text',
      disabled: true,
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_LONGITUDE,
      label: ['titles:longitude', 'Longitude'],
    },
    {
      isRequired: true,
      inputWrapperStyles,
      type: 'countrySelect',
      fieldName: GC.FIELD_COUNTRY,
      label: ['titles:country', 'Country'],
    },
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_STATE,
      label: ['titles:state', 'State'],
    },
  ],
};

export const locationSectionSettings2 = [
  locationFieldset1,
  locationFieldset2,
];
