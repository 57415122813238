/* eslint-disable */
import * as R from 'ramda';
import { createGlobalStyle } from 'styled-components';
//////////////////////////////////////////////////

 const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${R.pathOr('#b1b1b1', ['theme', 'colors', 'light', 'darkGrey'])};
  }
  /* "react-google-maps" */
  .gm-style button.gm-control-active * {
    box-sizing: unset;
  }
  .gm-style .gmnoprint * {
    box-sizing: unset;
  }
  /* 'react-pivottable/pivottable.css' */
  #pivot-container * {
    z-index: unset;
  }
  // TODO: check why we added that all styles
  /* .pvtUi * {
    z-index: unset;
  }
  table.pvtUi .pvtAxisContainer, .pvtVals, .pvtRenderers {
    background: #2073a1;
  }
  table.pvtUi span.pvtAttr, .pvtDropdownValue {
    background: white;
    border-radius: 2px;
    border: 1px solid white;
  }
  td.pvtRenderers {
    top: 0;
    left: 0;
    z-index: 3;
    position: sticky;
  }
  td.pvtAxisContainer.pvtUnused.pvtHorizList {
    top: 0;
    z-index: 2;
    position: sticky;
  }
  td.pvtVals {
    left: 0;
    top: 43px;
    z-index: 2;
    position: sticky;
  }
  td.pvtAxisContainer.pvtHorizList.pvtCols {
    top: 43px;
    z-index: 1;
    left: 267px;
    position: sticky;
  }
  td.pvtAxisContainer.pvtVertList.pvtRows {
    left: 0;
    z-index: 1;
    position: sticky;
  }
  .fm-toolbar-group-left,
  .fm-toolbar-group-right,
  #fm-pivot-view .fm-ui-element.fm-aggregations-view,
  #fm-pivot-view .fm-grid-layout .fm-cols-filter,
  #fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list {
    z-index: 12!important;
  }
  .fm-branding-bar {
    display: none;
  }
  .fm-list-wrapper {
    z-index: 11;
  }
  .fm-ui-element.fm-sheet-canvas.fm-data-sheet {
    pointer-events: none;
  } */
  // leaflet
  .leaflet-marker-inner:hover {
    transform: scale(1.1);
  }
  .leaflet-marker-inner::after {
    top: 26px;
    right: 50%;
    content: '';
    position: absolute;
    transform: translateX(50%);
    border: 13px solid transparent;
    border-top: 18px solid #2073a1;
    -webkit-transform: translateX(50%);
  }
  .leaflet-marker-inner.selected::after {
    border-top: 18px solid #b1b1b1;
  }
  .leaflet-marker-inner.greenColor::after {
    border-top: 18px solid #1d9206;
  }
  .leaflet-control-container .leaflet-routing-container-hide {
    display: none;
  }
`;

export default GlobalStyle;
/* eslint-enable */
