import * as R from 'ramda';
import { put, call, take, race, fork, select } from 'redux-saga/effects';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../common/actions';
// components
import { closeModal } from '../components/modal/actions';
// features
import { makeSelectCurrentBranchGuid } from '../features/branch/selectors';
import { makeSelectAccessToPagesList, makeSelectInitialDataLoadedStatus } from '../features/permission/selectors';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
//////////////////////////////////////////////////

export function* catchSaga({ name, error }: Object) {
  try {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, `${name} exception`);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, `${name} catchSaga exception`);
  }
}

export function* crudSaga({
  method,
  mapper,
  options,
  payload,
  endpoint,
  showFailMsg,
  callbackSaga,
  successAction,
  parentSagaName,
  successMessage,
  shouldCloseModal,
  showSuccessMessage,
}: Object) {
  try {
    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      let actionData = G.ifElse(R.equals(method, 'delete'), payload, data);

      if (mapper) {
        actionData = mapper(actionData);
      }

      if (G.isFunction(successAction)) {
        yield put(successAction(actionData));
      }

      if (G.isFunction(callbackSaga)) {
        yield fork(callbackSaga);
      }

      if (shouldCloseModal) {
        yield put(closeModal());
      }

      if (showSuccessMessage) {
        yield call(G.showToastrMessage, 'success', successMessage);
      }
    } else {
      yield call(G.handleFailResponse, res, `${parentSagaName} crud fail`, showFailMsg);
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, `${parentSagaName} crud exception`);
  }
}

const getRouteFromPayload = (payload: any) => {
  if (G.isString(payload)) return payload;

  if (G.isObject(payload)) {
    const { route, pathname } = payload;

    return R.or(route, pathname);
  }
};

export function* visitPageSaga(payload: any, type: string) {
  try {
    const route = getRouteFromPayload(payload);
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);

    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }

    if (R.not(isAllowed)) {
      yield put({ type, payload: route });
      yield take(`${type}_ALLOWED`);
    }
  } catch (error) {
    yield call(G.handleException, error, `visitPageSaga ${type} exception`);
  }
}

export function* getObjectWithCurrentBranchGuidParamSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    return {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
  } catch (error) {
    yield call(G.handleException, error, 'getObjectWithCurrentBranchGuidSaga exception');
    return {
      params: {
        [GC.FIELD_BRANCH_GUID]: null,
      },
    };
  }
}

export function* openWindowSaga(data: Object) {
  const { url, name, reloadLocation } = data;

  const params = `
    location=1,width=800,height=650,
    left=${R.divide(R.subtract(window.screen.width, 800), 2)},
    top=${R.divide(R.subtract(window.screen.height, 650), 2)},
  `;
  const connectedWindow = yield call(window.open, url, name, params);
  const runner = yield call(setInterval, () => {
    if (connectedWindow.closed) {
      clearInterval(runner);

      if (reloadLocation) {
        location.reload(); // eslint-disable-line
      }
    }
  }, 1000);
}

export function* sequenceAll(all: Array) {
  // eslint-disable-next-line no-restricted-syntax
  for (const task of all) {
    yield task;
  }
}
