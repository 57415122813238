import React from 'react';
import styled from 'styled-components';
// svgs
import amousLogo from './amous.svg';
//////////////////////////////////////////////////

const LogoWrapper = styled.div`
  position: relative;
`;

// Adjust styles if necessary
const Arrow = styled.div`
  width: 40px;
  left: 300px;
  height: 40px;
  bottom: 37px;
  position: absolute;
  background-size: contain;
  transform: rotate(-90deg);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/arrow.gif");
`;

// Info: For some reason the arrow.gif is not loaded via react when is attached to svg file
const AmousLogoComponent = (props: Object) => {
  const { imageWidth } = props;

  return (
    <LogoWrapper>
      <img src={amousLogo} alt='Amous Logo' width={imageWidth || '400px'} />
    </LogoWrapper>
  );
};

const AmousLogoAiComponent = (props: Object) => {
  const { imageWidth } = props;

  return (
    <LogoWrapper>
      <img src={amousLogo} alt='Amous Logo' width={imageWidth || '400px'} />
      <Arrow />
    </LogoWrapper>
  );
};

export {
  AmousLogoComponent,
  AmousLogoAiComponent,
};

// Simple EasyInOut function, don't remove
// const easeInOutQuint = (t: number) => t * t * (3 - (2 * t));
// Calculates animateTransform values for easyInOut animation
// const animationStep = (x1: number, x2: number) => {
//   let j = 0;
//   let result = '';

//   const diff = x1 - x2;
//   const step = -(diff / 10);

//   for (let i = x1; x1 > x2 ? i >= x2 : i <= x2; i += step) {
//     const eIO = easeInOutQuint(j);
//     const current = (x1 - (diff * eIO)).toFixed(1);

//     result += `${current}; `;
//     j += 0.1;
//   }

//   return result;
// };

//Instance console.log(animationStep(25, -30));

