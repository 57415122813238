import * as R from 'ramda';
import { createTheme } from '@mui/material/styles';
//////////////////////////////////////////////////

const hexColors = {
  '#EDEFF1': '#EDEFF1',
  '#FAFAFB': '#FAFAFB',
  '#E4E4E4': '#E4E4E4',
  '#7D828C': '#7D828C',
  '#2B5293': '#2B5293',
  '#F1F1F1': '#F1F1F1',
  '#E15141': '#E15141',
  '#4896EB': '#4896EB',
  '#67AC5B': '#67AC5B',
  '#F4C344': '#F4C344',
  '#58AE95': '#58AE95',
  '#C4C4C4': '#C4C4C4',
  '#E0E0E0': '#E0E0E0',
  '#F6F6F6': '#F6F6F6',
  '#999999': '#999999',
};

const rgbaColors = {
  white07: 'rgba(255, 255, 255, 0.7)',
  boxShadowGrey: 'rgba(0, 0, 0, 0.2)',
  steelBlue02: 'rgba(71, 91, 116, 0.2)',
  boxShadowRed: 'rgba(206, 40, 40, 0.5)',
  transparentBlack: 'rgba(0, 0, 0, 0.5)',
  boxShadowBlue: 'rgba(32, 115, 161, 0.5)',
  transparentRed: 'rgba(206, 40, 40, 0.1)',
  transparentBlue: 'rgba(58,121,159, 0.5)',
  transparentPink: 'rgba(206, 40, 40, 0.5)',
  transparentRed2: 'rgba(225, 81, 65, 0.08)',
  transparentGrey: 'rgba(160, 160, 160, 0.5)',
  transparentYellow: 'rgba(237, 180, 32, 0.3)',
  mainDarkTransparent: 'rgba(53, 53, 53, 0.5)',
  boxShadowLightGrey: 'rgba(221, 221, 221, 0.5)',
  transparentDarkBlue: 'rgba(69, 115, 159, 0.05)',
  lightGreenHalfOpacity: 'rgba(206, 244, 195, 0.5)',
};

export const colors = {
  demin: '#0063b0',
  black: '#000000',
  white: '#ffffff',
  bgGrey: '#edeff1',
  cobalt: '#005395',
  bgBlue: '#e7e9fd',
  silver: '#c5c0c0',
  orange: '#edb420',
  warning: '#FFCC00',
  darkRed: '#930000',
  darkRed2: '#E15141',
  nearDark: '#350828',
  nearGray: '#2c594d',
  darkGrey: '#666666',
  navyBlue: '#007ada',
  darkBlue: '#005395',
  neroBlack: '#252323',
  lightGrey: '#dddddd',
  nearVivid: '#10c710',
  whiteGrey: '#f7f7f7',
  titleGrey: '#797272',
  titleGrey2: '#989898',
  lightGreen: '#60bb71',
  curiosBlue: '#2185D0',
  whiteSmoke: '#f5f5f5',
  mustardYellow: '#BF9322',
  veryLightGrey: '#cccccc',
  greyMatterhorn: '#4c4c4c',
  middleDarkOrange: '#dd6143',
  reactSelectOptionHoverBgColor: '#DEEBFF',
  // new colors
  lime: '#bef42d',
  azure: '#1976d2',
  green: '#52d937',
  mainBlue: '#3a719f',
  poloBlue: '#94b6d2',
  textDark: '#282828',
  lightLime: '#ddff88',
  lightBlue: '#d7dfe7',
  softGreen: '#569d48',
  steelBlue: '#475B74',
  bgDarkGrey: '#c2ccdf',
  lightBrown: '#76595F',
  borderGray: '#e2e2e2',
  bgLightGrey: '#f8f8f8',
  bgLightBlue: '#dbe8ff',
  titleDarkBlue: '#001C64',
  ...hexColors,
  ...rgbaColors,
  dark: {
    grey: '#c8c8c8',
    blue: '#015295',
    green: '#42ce28',
    yellow: '#FFCC00',
    mainRed: '#ce2828',
    teaGreen: '#cef4c3',
    mainDark: '#353535',
    darkGrey: '#91979A',
    darkBlue: '#45739F',
    sweetPink: '#ffaeae',
    mainLight: '#f3f3f3',
    lightGrey: '#d8d8d8',
    lighterGrey: '#f2f2f2',
  },
  light: {
    red: '#d94e0c',
    grey: '#707070',
    blue: '#2073a1',
    green: '#1d9206',
    black: '#353535',
    yellow: '#fbe226',
    mainRed: '#ce2828',
    darkRed: '#930000',
    darkGrey: '#b1b1b1',
    teaGreen: '#cef4c3',
    mainDark: '#868686',
    lightRed: '#ff0000',
    blueGrey: '#647C8A',
    darkBlue: '#354A65',
    mainLight: '#ffffff',
    lightBlue: '#add8e6',
    lightGrey: '#f3f3f3',
    lightPink: '#ffe6e6',
    sweetPink: '#ffaeae',
    darksGrey: '#afafaf',
    lightGreen: '#aae974',
    middleGrey: '#cacaca',
    teaGreenTwo: '#a5e592',
    lighterGrey: '#fafafa',
    lightOrange: '#f0b89e',
    navbarToggle: '#9AA4B1',
    lightBlueGrey: '#C1D0E1',
    sweetPinkDark: '#f58686',
    navbarBackground: '#344963',
    warningBackground: '#f5e3b6',
    criticalBackground: '#f4e3e3',
    userBoxBackground: '#FFFFFF50',
    transparentLightBlue: '#d2e3ec',
    notificationsBackground: '#EF2736',
    navbarButtonEditBackground: '#FFFFFF40',
    navbarButtonHoverBackground: '#FFFFFF20',
    createPopoverHoverBackground: '#34496320',
  },
};

const shadows = {
  0: '0 0 8px 0 rgb(0 0 0 / 20%)',
  standard: '0 0 8px 0 rgb(0 0 0 / 20%)',
  cardBoxShadow: `0 0 5px 0 ${colors.boxShadowGrey}`,
};

const defaultTheme = createTheme();
const breakpoints = R.prop('breakpoints', defaultTheme);
const bKeys = R.prop('keys', breakpoints);
const bValues = R.prop('values', breakpoints);
const unit = R.prop('unit', breakpoints);
const space = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const MUITheme = defaultTheme;

export const Theme = R.mergeRight(
  defaultTheme,
  {
    space,
    colors,
    shadows,
    // There is a difference between breakpoints format
    // between style-system and mui
    // I remap breakpoints for styled-system
    breakpoints: R.map((key: string) => R.concat(R.toString(R.prop(key, bValues)), unit), bKeys),
  },
);

export default {
  colors,
  shadows,
  buttons: {
    dispatchBtn: {
      bgColor: colors.darkRed,
      textColor: colors.white,
    },
    assignBtn: {
      bgColor: colors.light.blue,
      textColor: colors.light.mainLight,
    },
    cancelBtn: {
      bgColor: colors.light.black,
      textColor: colors.light.mainLight,
    },
    saveBtn: {
      bgColor: colors.light.mainRed,
      textColor: colors.light.mainLight,
    },
    saveAsNewBtn: {
      bgColor: colors.light.mainRed,
      textColor: colors.light.mainLight,
    },
    uploadBtn: {
      textColor: colors.dark.mainDark,
      borderColor: colors.light.darkGrey,
    },
    disabled: {
      bgColor: colors.lightGrey,
      textColor: colors.darkGrey,
      borderColor: colors.light.darkGrey,
    },
  },
  charts: {
    bgColor: colors.light.mainLight,
    borderColor: colors.light.darkRed,
    bgTextColor: colors.light.lightGrey,
  },
  chat: {
    iconColorDark: colors.black,
    contentBgColor: colors.white,
    iconColorRed: colors.light.red,
    inputColor: colors.light.black,
    userMessageColor: colors.white,
    iconColor: colors.light.darkRed,
    messageColor: colors.light.black,
    cloHeaderColor: colors.light.black,
    userBoxColor: colors.light.darkRed,
    iconColorGreen: colors.light.green,
    iconColorYellow: colors.light.yellow,
    inputBgColor: colors.light.lightGrey,
    recordingColor: colors.light.mainLight,
    messageBgColor: colors.light.lightGrey,
    telHeaderColor: colors.light.mainLight,
    iconColorWhite: colors.light.mainLight,
    cloHeaderBgColor: colors.dark.teaGreen,
    recordingRedColor: colors.light.darkRed,
    telHeaderBgColor: colors.dark.sweetPink,
    sendMessageColor: colors.light.mainLight,
    sendMessageBgColor: colors.light.darkRed,
    messageBorderColor: colors.light.darkGrey,
    userMessageBgColor: colors.light.mainDark,
    chatWrapperBgColor: colors.light.mainLight,
  },
  common: {
    linkColor: colors.light.blue,
    alertTextColor: colors.light.mainRed,
    whiteTextColor: colors.light.mainLight,
  },
  createButton: {
    shadowColor: colors.dark.green,
    defaultColor: colors.light.darkRed,
  },
  dimmer: {
    bgColor: colors.light.mainLight,
    tableBgColor: colors.light.darkGrey,
    contentBgColor: colors.light.mainDark,
  },
  downFilter: {
    textColor: colors.light.black,
    bgColor: colors.dark.lightGrey,
  },
  dropDown: {
    bgColor: colors.white,
    textColor: colors.black,
    itemTextColor: colors.black,
    borderColor: colors.light.lightGrey,
    head: {
      hover: { textColor: colors.light.darkRed },
    },
    body: {
      bgColor: colors.light.lightGrey,
      borderColor: rgbaColors.transparentPink,
    },
    item: {
      hover: {
        bgColor: colors.light.lightGrey,
        textColor: colors.light.darkRed,
      },
    },
  },
  forms: {
    titleColor: colors.black,
    bgColor: colors.light.mainLight,
    labelsWrapper: { bgColor: colors.light.lightGrey },
    actionsFooter: {
      bgColor: colors.light.lightGrey,
      borderTopColor: colors.lightGrey,
    },
    group: {
      title: {
        textColor: colors.white,
        bgColor2: colors.mainBlue,
        bgColor: colors.light.mainRed,
      },
    },
    checkboxes: {
      bgColor: colors.white,
      bgColorChecked: colors.white,
      borderColor: colors.light.grey,
      textColorChecked: colors.light.green,
      additionalTextColor: colors.light.red,
      borderColorChecked: colors.light.green,
      additionalBorderColor: colors.light.red,
    },
    checkboxes2: {
      textColor: colors.white,
      bgColor: colors.dark.blue,
      borderColor: colors.white,
    },
    inputs: {
      textColor: colors.black,
      labelColor: colors.black,
      borderColor: colors.light.grey,
      bgColor: colors.light.mainLight,
      bgDisabled: colors.light.lightGrey,
      errorTextColor: colors.light.lightRed,
      borderColorErr: colors.light.lightRed,
      borderFocusedColor: colors.light.blue,
      borderColorRate: colors.veryLightGrey,
      borderErrorColor: colors.light.lightRed,
    },
  },
  header: {
    bgColor: colors.white,
    textColor: colors.light.black,
    dividerVertical: { bgColor: colors.light.darkGrey },
    icon: {
      width: '15px',
      color: colors.black,
    },
  },
  icons: {
    iconColor: colors.dark.blue,
    iconColorGrey: colors.light.black,
    iconColorWhite: colors.light.mainLight,
  },
  info: {
    hoverIconColor: colors.white,
    bgColor: colors.dark.mainLight,
    iconColor: colors.dark.mainRed,
    textColor: colors.dark.mainDark,
    borderColor: colors.dark.mainRed,
  },
  loader: { bgColor: colors.darkGrey },
  listActions: {
    bgColor: colors.light.lightGrey,
    borderColor: rgbaColors.transparentPink,
    newBorderColor: rgbaColors.transparentBlue,
    header: {
      bgColor: colors.light.mainRed,
      countBorderColor: colors.white,
      countBgColor: colors.light.darkRed,
    },
  },
  map: {
    markerColor: colors.light.blue,
    bgColor: colors.light.mainLight,
    directionsColor: colors.nearVivid,
    markerTextColor: colors.light.blue,
    redMarkerColor: colors.light.mainRed,
    infoBoxBorderColor: colors.light.blue,
    infoBoxBgColor: colors.light.lightGrey,
    completedMarkerColor: colors.light.green,
    infoBoxBorderColorRed: colors.light.darkRed,
  },
  multiswitch: {
    bgSwith: colors.light.green,
    textColor: colors.light.mainLight,
    bgSwithWrapper: colors.light.darkGrey,
  },
  pages: {
    layOutBgColor: colors.light.mainLight,
    listsPages: {
      header: {
        borderColor: colors.lightGrey,
        textColor: colors.dark.mainDark,
        bgColor: colors.light.lightGrey,
      },
    },
    listTitle: {
      color: colors.light.mainLight,
      bgColor: colors.light.darkRed,
      countBgColor: colors.light.darkRed,
      infoBoxBgColor: colors.light.green,
      infoBoxColor: colors.light.mainLight,
      countBorderColor: colors.light.mainLight,
    },
  },
  modal: {
    textColor: colors.light.black,
    bgColor: colors.light.lightGrey,
    confirmTextColor: colors.light.black,
    boxShadow: rgbaColors.transparentPink,
    controlBgColor: colors.light.darkGrey,
  },
  reactModal: {
    bgColor: colors.white,
    border: '1px solid rgb(204, 204, 204)',
    overlayBgColor: 'rgba(53, 53, 53, 0.5)',
    boxShadow: '0 0 8px 1px rgba(206, 40, 40, 0.5)',
  },
  progressBar: {
    bgColor: colors.light.darkGrey,
    progressBgRed: colors.light.darkRed,
    progressBgGreen: colors.light.green,
    progressBgYellow: colors.light.yellow,
  },
  report: {
    borderColor: colors.lightGrey,
    textColor: colors.light.black,
    linkTextColor: colors.light.blue,
    headBgColor: colors.light.lightGrey,
    fixedBorderColor: colors.light.mainRed,
    form: { bgColor: colors.light.mainLight },
    panel: {
      generateFile: {
        hoverIconColor: colors.light.darkRed,
        hoverTextColor: colors.light.darkRed,
      },
    },
  },
  roles: {
    addModal: {
      bgColor: colors.white,
      titleColor: colors.black,
    },
  },
  summary: {
    telLabelColor: '#fe9d5f',
    cloLabelColor: '#fe9d5f',
    negativeColor: '#d42129',
    positiveColor: '#2b9200',
    bodyBgColor: colors.white,
    groupBodyBgColor: '#ffffff',
    telLabelItemColor: '#ffdcc7',
    cloLabelItemColor: '#ffdcc7',
    groupHeaderBgColor: '#f2f2f2',
    buttonTextColor: colors.white,
    progressColor: colors.light.black,
    borderColor: colors.light.darkRed,
    bodyTextColor: colors.light.black,
    buttonBgColor: colors.light.darkRed,
    groupItemBorderColor: colors.light.lighterGrey,
  },
  status: {
    RATED: '#ed7c20',
    BOOKED: colors.green,
    QUOTE: colors.darkRed,
    PLANNED: colors.orange,
    pending: colors.orange,
    QUOTED: colors.darkRed,
    CANCELED: colors.darkRed,
    ARRIVED: colors.light.blue,
    PENDING: colors.light.blue,
    DISPATCHED: colors.darkRed,
    DISPATCH_EXPIRED: '#f61515',
    IN_TRANSIT: colors.light.blue,
    DELIVERED: colors.light.green,
    COMPLETED: colors.light.green,
    completed: colors.light.green,
    CHECKED_IN: colors.light.yellow,
    UNSCHEDULED: colors.light.mainDark,
    DISPATCH_REJECTED: colors.light.mainRed,
  },
  popper: {
    telBgColor: colors.light.red,
    redBgColor: colors.light.red,
    textColor: colors.light.black,
    borderColor: colors.light.black,
    bgColor: colors.light.lightGrey,
    iconColor: colors.light.darkRed,
    actionIconColor: colors.light.blue,
    actionTextColor: colors.light.blue,
    redTextColor: colors.light.darkRed,
    titleBgColor: colors.light.darkGrey,
    cloBgColor: colors.light.lightGreen,
    successStatusColor: colors.light.green,
    whiteTextColor: colors.light.mainLight,
    normalStatusColor: colors.light.yellow,
    unactiveIconColor: colors.light.darkGrey,
    dangerStatusColor: colors.light.lightRed,
  },
  detailPage: {
    container: {
      bgColor: colors.light.mainLight,
      borderColor: colors.light.darkGrey,
    },
    containerHeader: {
      textColor: colors.light.black,
      borderColor: colors.light.black,
      bgColor: colors.light.lightGrey,
      cloBgColor: colors.light.teaGreen,
      telBgColor: colors.light.sweetPink,
    },
    popper: {
      telBgColor: colors.light.red,
      redBgColor: colors.light.red,
      textColor: colors.light.black,
      bgColor: colors.light.lightGrey,
      iconColor: colors.light.darkRed,
      borderColor: colors.light.black,
      actionIconColor: colors.light.blue,
      actionTextColor: colors.light.blue,
      redTextColor: colors.light.darkRed,
      cloBgColor: colors.light.lightGreen,
      titleBgColor: colors.light.darkGrey,
      whiteTextColor: colors.light.mainLight,
      successStatusColor: colors.light.green,
      normalStatusColor: colors.light.yellow,
      unactiveIconColor: colors.light.darkGrey,
      dangerStatusColor: colors.light.lightRed,
    },
    content: {
      telBgColor: colors.light.red,
      textColor: colors.light.black,
      iconColor: colors.light.darkRed,
      bgColor: colors.light.mainLight,
      grayIconColor: colors.light.black,
      redTextColor: colors.light.darkRed,
      actionTextColor: colors.light.blue,
      borderColor: colors.light.darkGrey,
      actionIconColor: colors.light.blue,
      cloBgColor: colors.light.lightGreen,
      labelBgColor: colors.light.lightGrey,
      normalStatusColor: colors.light.yellow,
      whiteTextColor: colors.light.mainLight,
      successStatusColor: colors.light.green,
      whiteIconColor: colors.light.mainLight,
      tableHeaderColor: colors.light.mainLight,
      dangerStatusColor: colors.light.lightRed,
      tableHeaderBgColor: colors.light.darkRed,
    },
    stops: {
      telColor: colors.white,
      dropColor: colors.white,
      whiteIconColor: colors.white,
      cloColor: colors.light.black,
      textColor: colors.light.black,
      pickupColor: colors.light.black,
      bgColor: colors.light.mainLight,
      iconColor: colors.light.darkRed,
      redBgColor: colors.light.darkRed,
      cloBgColor: colors.light.teaGreen,
      grayIconColor: colors.light.black,
      dropCloBgColor: colors.light.green,
      actionTextColor: colors.light.blue,
      telBgColor: colors.light.sweetPink,
      borderColor: colors.light.mainDark,
      labelBgColor: colors.light.lightGrey,
      pendingStatusColor: colors.light.blue,
      whiteTextColor: colors.light.mainLight,
      successStatusColor: colors.light.green,
      darkSectionColor: colors.light.darkGrey,
      dropTerminalBgColor: colors.light.black,
      pickupTelBgColor: colors.light.sweetPink,
      dangerStatusColor: colors.light.lightRed,
      normalStatusColor: colors.light.lightGreen,
      pickupTerminalBgColor: colors.light.darkGrey,
    },
  },
  dispatchBoardInnerTable: {
    telColor: colors.black,
    cloColor: colors.light.black,
    countBorderColor: colors.white,
    togglerBorderColor: colors.white,
    cloBgColor: colors.light.teaGreen,
    cloBorderColor: colors.light.black,
    telBgColor: colors.light.sweetPink,
    telBorderColor: colors.light.darkGrey,
    cloCountBgColor: colors.light.teaGreenTwo,
    telCountBgColor: colors.light.sweetPinkDark,
  },
  dispatchBoardTableTitle: {
    textColor: colors.light.black,
    nameColor: colors.light.darkRed,
    cloBgColor: colors.light.teaGreen,
    telBgColor: colors.light.sweetPink,
    specialTextColor: colors.light.blue,
  },
  dispatchBoardPopper: {
    redBgColor: colors.light.red,
    textColor: colors.light.black,
    bgColor: colors.light.mainLight,
    borderColor: colors.light.black,
    iconColor: colors.light.darkRed,
    cloBgColor: colors.dark.teaGreen,
    telBgColor: colors.dark.sweetPink,
    redTextColor: colors.light.darkRed,
    actionTextColor: colors.light.blue,
    stopIconBg: colors.light.lightGrey,
    actionIconColor: colors.light.blue,
    titleBgColor: colors.light.darkGrey,
    stopPendingColor: colors.light.blue,
    greyBgColor: colors.light.middleGrey,
    stopSuccessColor: colors.light.green,
    stopRejectColor: colors.light.darkRed,
    mainRedTextColor: colors.light.mainRed,
    normalStatusColor: colors.light.yellow,
    successStatusColor: colors.light.green,
    whiteTextColor: colors.light.mainLight,
    unactiveIconColor: colors.light.darkGrey,
    dangerStatusColor: colors.light.lightRed,
  },
  tables: {
    header: {
      textColor: colors.light.black,
      bgColor: colors.light.lightGrey,
      onGreyHeaderTextColor: colors.black,
      borderColor: colors.light.lightGrey,
      greyBgColor: colors.light.lightGrey,
    },
    subTitle: {
      bgColor: '#d7e3eb',
    },
    rows: {
      textColor: colors.light.black,
      borderColor: colors.lightGrey,
      bgColor: colors.light.mainLight,
      toggleIconColor: colors.light.green,
      hoverBgColor: colors.light.lightBlue,
      borderColorActive: colors.light.green,
      bgColorActive: colors.light.lightGreen,
      additionalBorderColor: colors.light.red,
      notSelectedBgColor: colors.light.lightPink,
      additionalBgColor: colors.light.lightOrange,
      additionalToggleIconColor: colors.light.red,
      highlightedBgColor: colors.light.transparentLightBlue,
    },
  },
  scroll: { bgColor: colors.light.darkGrey },
  statusLabel: {
    borderColor: colors.white,
    bgPositiveInternal: colors.white,
    bgNegativeExternal: colors.lightGrey,
    bgPositiveExternal: colors.light.green,
    bgNegativeInternal: colors.light.mainRed,
  },
  toggle: {
    rightTextChecked: colors.black,
    textUnchecked: colors.lightGrey,
    iconColor: colors.light.mainLight,
    checkedBgColor: colors.light.green,
    borderColor: colors.light.mainLight,
    leftTextChecked: colors.light.green,
    circleBgColor: colors.light.mainLight,
    circleBorderColor: colors.light.green,
    uncheckedBgColor: colors.light.darkGrey,
    checkedCircleBorderColor: colors.light.darkGrey,
  },
  tooltip: {
    textColor: colors.white,
    bgColor: colors.darkGrey,
  },
  createDO: {
    pricingBgColor: colors.dark.lightGrey,
    pricingSectionDividerColor: colors.black,
    searchInput: { borderColor: colors.black },
    arrowIcon: { color: colors.light.darkRed },
    loadStepsWrapper: { borderColor: colors.lightGrey },
    loadStepDivider: { borderColor: colors.light.darkRed },
    deductionTotal: {
      textColor: colors.white,
      bgColor: colors.light.mainRed,
    },
    chargeTotal: {
      textColor: colors.white,
      bgColor: colors.light.blue,
    },
    total: {
      textColor: colors.white,
      bgColor: colors.light.green,
    },
    titleTotal: {
      textColor: colors.light.black,
      bgColor: colors.dark.lightGrey,
    },
    rightNav: {
      borderColor: colors.lightGrey,
      bgColor: colors.light.lightGrey,
    },
    invoicesTotal: {
      textColor: colors.white,
      bgColor: colors.light.blueGrey,
    },
    loadStepHeader: {
      bgColor: colors.light.lightGrey,
      borderColor: colors.light.darkRed,
      borderColorSuccess: colors.light.green,
    },
    loadStepHeaderLeftIcon: {
      bgColor: colors.light.lightGrey,
      svgsColor: colors.light.darkRed,
      borderColor: colors.light.darkRed,
      bgColorActive: colors.light.darkRed,
      svgsSuccessColor: colors.light.green,
      svgsActiveColor: colors.light.lightGrey,
    },
  },
};
